// 记录加载初始化状态
let isSdkReady = false

// 记录加载配置状态
let isConfig = false

export default class {

  // 初始化
  static init() {
    ysf("onready", function () {
      isSdkReady = true
    });
  }

  // 配置客户参数
  static Config(info) {
    ysf("config", {
      uid: info.uid,
      groupid: info.gid,
      data: JSON.stringify(info.data),
      success: function () {
        isConfig = true
      }
    });
  }

  // 打开客服链接
  static openSdk() {
    if (isSdkReady && isConfig) {
      let url = ysf('url')
      window.location.replace(url);
      // location.replace = ysf('url')
    } else {
      // alert("sdk尚未加载成功，请稍后再试")
      return 'sdk尚未加载成功，请稍后再试'
    }
  }
}