/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/feedback',
    component: () => import('@/views/Feedback/index.vue'),
    meta: {
      title: '联系客服',
      keepAlive: false
    }
  },
  {
    path: '/faq',
    component: () => import('@/views/FAQ/index.vue'),
    meta: {
      title: '常见问题',
      keepAlive: false
    }
  },
  {
    path: '/faqdetail',
    component: () => import('@/views/FAQ/detail.vue'),
    meta: {
      title: '常见问题',
      keepAlive: false
    }
  },
  {
    path: '/agreement',
    component: () => import('@/views/Agreement/index.vue'),
    meta: {
      title: '协议',
      keepAlive: false
    }
  },
  {
    path: '/privacyagreement/:id',
    component: () => import('@/views/PrivacyAgreement/index.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/whhAgreement',
    component: () => import('@/views/whhAgreement/index.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/zegAgreement',
    component: () => import('@/views/zegAgreement/index.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/bzkAgreement',
    component: () => import('@/views/bzkAgreement/index.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/twAgreement',
    component: () => import('@/views/twAgreement/index.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/wnzmAgreement',
    component: () => import('@/views/wnzmAgreement/index.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/subscription',
    component: () => import('@/views/Subscription/index.vue'),
    meta: {
      title: '订阅管理',
      keepAlive: false
    }
  },
  {
    path: '/course',
    component: () => import('@/views/Course/index.vue'),
    meta: {
      title: '教程',
      keepAlive: false
    },
    children: [
      {
        path: 'android',
        component: () => import('@/views/Course/android.vue'),
        meta: {
          title: '组件教程',
          keepAlive: false
        },
      },
      {
        path: 'ios',
        component: () => import('@/views/Course/ios.vue'),
        meta: {
          title: '组件教程',
          keepAlive: false
        },
      },
      {
        path: 'setIosChargingAnimation',
        component: () => import('@/views/Course/setIosChargingAnimation.vue'),
        meta: {
          title: '如何设置充电动画',
          keepAlive: false
        },
      },
      {
        path: 'closeIosChargingAnimation',
        component: () => import('@/views/Course/closeIosChargingAnimation.vue'),
        meta: {
          title: '如何关闭充电动画',
          keepAlive: false
        },
      },
      {
        path: 'setDesktopIcon',
        component: () => import('@/views/Course/setDesktopIcon.vue'),
        meta: {
          title: '如何添加图标',
          keepAlive: false
        },
      },
      {
        path: 'setIosTheme',
        component: () => import('@/views/Course/setIosTheme.vue'),
        meta: {
          title: '如何设置主题',
          keepAlive: false
        },
      },
      {
        path: 'setAndTheme',
        component: () => import('@/views/Course/setAndTheme.vue'),
        meta: {
          title: '如何设置主题',
          keepAlive: false
        },
      },
      {
        path: 'setPreventDoubleJump',
        component: () => import('@/views/Course/setPreventDoubleJump.vue'),
        meta: {
          title: '无二次跳转教程',
          keepAlive: false
        },
      },
      {
        path: 'setLiveWallpaper',
        component: () => import('@/views/Course/setLiveWallpaper.vue'),
        meta: {
          title: '如何设置动态壁纸',
          keepAlive: false
        },
      },
      {
        path: 'customIcon',
        component: () => import('@/views/Course/customIcon.vue'),
        meta: {
          title: '自定义图标教程',
          keepAlive: false
        },
      },
      {
        path: 'lockBackground',
        component: () => import('@/views/Course/lockBackground.vue'),
        meta: {
          title: '锁定后台教程',
          keepAlive: false
        },
      },
      {
        path: 'androidList',
        component: () => import('@/views/Course/androidList.vue'),
        meta: {
          title: '教程',
          keepAlive: false
        },
      },
      {
        path: 'iosList',
        component: () => import('@/views/Course/iosList.vue'),
        meta: {
          title: '教程',
          keepAlive: false
        },
      },
      {
        path: 'projectionScreenAndroid',
        component: () => import('@/views/Course/projectionScreenAndroid.vue'),
        meta: {
          title: '投屏帮助',
          keepAlive: false
        },
      },
      {
        path: 'projectionScreenIos',
        component: () => import('@/views/Course/projectionScreenIos.vue'),
        meta: {
          title: '投屏帮助',
          keepAlive: false
        },
      }
    ]
  },
  {
    path: '/idol',
    component: () => import('@/views/Idol/index.vue'),
    meta: {
      title: '我的爱豆',
      keepAlive: false
    }
  },
  {
    path: '/inform',
    component: () => import('@/views/Inform/index.vue'),
    meta: {
      title: '意见反馈',
      keepAlive: false
    }
  },
  {
    path: '/score',
    component: () => import('@/views/Score/index.vue'),
    meta: {
      title: '撰写评论',
      keepAlive: false
    }
  },
  {
    path: '/share',
    component: () => import('@/views/Share/index.vue'),
    meta: {
      title: '分享',
      keepAlive: false
    },
    children: [
      {
        path: 'overdue/:id',
        component: () => import('@/views/Share/overdue/index.vue'),
        meta: {
          title: '逾期上岸不求人',
          keepAlive: false
        },
      },
    ]
  },
  {
    path: '/partner',
    component: () => import('@/views/Partner/index.vue'),
    meta: {
      title: '城市合伙人招募',
      keepAlive: false
    }
  },
  {
    path: '/fitness',
    component: () => import('@/views/Fitness/index.vue'),
    meta: {
      title: '健身',
      keepAlive: false
    },
    children: [
      {
        path: 'sportRecord',
        component: () => import('@/views/Fitness/sportRecord/index.vue'),
        meta: {
          title: '运动记录',
          keepAlive: false
        },
      },
    ]
  },
  {
    path: '/member',
    component: () => import('@/views/Member/index.vue'),
    meta: {
      title: '会员',
      keepAlive: false
    },
    children: [
      {
        path: 'common',
        component: () => import('@/views/Member/common/index.vue'),
        meta: {
          title: '普通会员',
          keepAlive: false
        },
      },
    ]
  },
  {
    path: '/qiyu',
    component: () => import('@/views/Qiyu/index.vue'),
    meta: {
      title: '在线客服',
      keepAlive: false
    }
  }
]